import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useLocation } from '@gatsbyjs/reach-router';
import './NotFound.scss';
import NotFoundBackground from 'src/images/NotFound-background.jpg';
import { navigate } from 'gatsby';

const NotFound = ( props ) => {
  const [t] = useTranslation('notFound');
  const location = useLocation();

  const doSearch = ( value ) => {
    if( window.searchAborter && window.searchAborter.abort ) {
      window.searchAborter.abort();
    }

    window.searchAborter = new AbortController();

    let lang = 'en';
    if( /^\/zh$/.test( window.location.pathname ) ) {
      lang = 'zh';
    }
    if( /^\/zh_tc$/.test( window.location.pathname ) ) {
      lang = 'zh_tc';
    }

    let url = process.env.GATSBY_WP_SEARCH_ENDPOINT + 'suggest?s=' + encodeURIComponent( value );
    url += '&l=' + lang;
    
    fetch( url, {
      signal: window.searchAborter.signal
    })
    .then( response => {
      window.searchAborter = null;

      response.json()
      .then( data => {
        if( data?.items?.length ) {        
          navigate( data.items[0].path, { replace: true } );
        }
      });
    })
    .catch( (e) => {
      console.log( e );
    })
    .finally( () => {
    });
  }

  useEffect(() => {
    doSearch( location.pathname );
  }, []);

  return (
    <div className="not-found" style={{ backgroundImage: `url('${NotFoundBackground}')` }}>
      <div className="not-found__modal">
        <div className="not-found__modal-body">
          <p className="not-found__modal-body__aplogize">
            {t('not-found-aplogize')}
          </p>
          <h1 className="not-found__modal-body__title">
            {t('not-found-title')}
          </h1>
          <p className="not-found__modal-body__description">
            {t('not-found-description')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFound;
